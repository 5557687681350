<template>
  <validation-observer ref="simpleRules">
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel</span>
        </template>
        <b-card title="Hakkımızda">
          <b-row>
            <b-col cols="12">
              <title-text />
            </b-col>
            <b-col
              cols="12"
            >
              <content-text />
            </b-col>
          </b-row>
        </b-card>
        <image-card />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Değerlerimiz</span>
        </template>
        <items-card />
      </b-tab>
    </b-tabs>

    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTabs, BTab,
} from 'bootstrap-vue'

import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TitleText from '@/views/Admin/About/elements/TitleText.vue'
import ContentText from '@/views/Admin/About/elements/ContentText.vue'
import ImageCard from '@/views/Admin/About/elements/ImageCard.vue'
import ItemsCard from '@/views/Admin/About/elements/itemsCard.vue'

export default {
  name: 'Index',
  components: {
    ItemsCard,
    BTab,
    BTabs,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BButton,
    TitleText,
    ContentText,
    ImageCard,
  },
  data() {
    return {
      required,
      imageUrl: this.$store.state.app.baseURL,
      itemsForm: false,
      selectItems: null,

    }
  },
  computed: {
    itemData() {
      return this.$store.getters['about/dataItem']
    },
    saveData() {
      return this.$store.getters['about/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getData()
    },
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('about/getDataItem')
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('about/saveData', this.itemData)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
