<template>
  <div>
    <div class="text-right mb-2">
      <b-button
        variant="primary"
        @click="activeItemsNewItemForm()"
      >
        <FeatherIcon icon="PlusIcon" /> Ekle
      </b-button>
    </div>
    <validation-observer ref="simpleRules">
      <b-card
        v-if="itemForm"
        :title="dataItem.title ? dataItem.title : 'Yeni Öğe'"
      >
        <b-form-group
          label="Başlık"
          label-for="title"
        >
          <validation-provider
            #default="{ errors }"
            name="Başlık"
            rules="required"
          >
            <b-form-input
              id="title"
              v-model="dataItem.title"
              placeholder="Başlık"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Alt Başlık"
          label-for="subtitle"
        >
          <validation-provider
            #default="{ errors }"
            name="Alt Başlık"
            rules="required"
          >
            <b-form-input
              id="subtitle"
              v-model="dataItem.subtitle"
              placeholder="Alt Başlık"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="İçerik"
          label-for="content"
        >
          <validation-provider
            #default="{ errors }"
            name="İçerik"
            rules="required"
          >
            <quill-editor
              v-model="dataItem.content"
              :options="editorOption"
              class="bg-white text-black-50"
            >
              <div
                id="content_toolbar_items"
                slot="toolbar"
              >
                <editor-toolbar />
              </div>
            </quill-editor>
            <small class="text-danger">{{ errors[0] }}</small>
            <b-form-group class="mt-2">
              <Items-Image-Card id="ItemsImageCards" />
            </b-form-group>
            <b-button
              variant="primary"
              class="mt-2"
              @click="submitForm"
            >
              Kaydet
            </b-button>
            <b-button
              variant="danger"
              class="mt-2 ml-2"
              @click="hideForm"
            >
              İptal
            </b-button>
          </validation-provider>
        </b-form-group>

      </b-card>
      <b-card
        title="Değerlerimiz"
        no-body
      >
        <b-card-header>
          <b-card-title>Liste</b-card-title>
        </b-card-header>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="datalist"
          striped
        >
          <template #cell(title)="data">
            {{ data.item.title }}
          </template>
          <template #cell(control)="data">
            <div class="text-right">
              <b-dropdown
                split
                text="Güncelle"
                variant="outline-primary"
                right
                size="sm"
                @click="activeItemsForm(data.item.id)"
              >
                <template #button-content>
                  <feather-icon
                    icon="EditIcon"
                  />
                  Güncelle
                </template>
                <b-dropdown-item
                  v-if="data.item.id"
                  @click="removeItemsForm(data.item.id)"
                >
                  <feather-icon icon="XIcon" />
                  <span class="align-middle ml-50">Sil</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput, BCard, BButton, BDropdown, BCardTitle, BCardHeader, BDropdownItem, BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'
import ItemsImageCard from '@/views/Admin/About/elements/ItemsImageCard.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ItemsCard',
  components: {
    BTable,
    BDropdownItem,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    EditorToolbar,
    ItemsImageCard,
  },
  data() {
    return {
      required,
      editorOption: {
        modules: {
          toolbar: '#content_toolbar_items',
        },
        theme: 'snow',
      },
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      itemForm: false,
    }
  },
  computed: {
    datalist() {
      return this.$store.getters['aboutItems/dataList']
    },
    dataItem() {
      return this.$store.getters['aboutItems/dataItem']
    },
    saveData() {
      return this.$store.getters['aboutItems/dataSaveStatus']
    },
    itemsCard() {
      return this.$store.getters['about/itemsCard']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getAboutItems()
        this.itemForm = false
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    localize('tr')
    this.getAboutItems()
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('aboutItems/saveData', this.dataItem)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
    getAboutItems() {
      return this.$store.dispatch('aboutItems/getDataList')
    },
    removeItemsForm(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('aboutItems/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    activeItemsForm(id) {
      this.dataItem.title = null
      this.dataItem.subtitle = null
      this.dataItem.content = null
      this.dataItem.image = null
      this.dataItem.thumb = null
      this.$store.dispatch('aboutItems/getDataItem', id)
      this.itemForm = true
    },
    activeItemsNewItemForm() {
      this.dataItem.title = null
      this.dataItem.subtitle = null
      this.dataItem.content = null
      this.dataItem.image = null
      this.dataItem.thumb = null
      this.itemForm = true
    },
    hideForm() {
      this.itemForm = false
    },
  },

}
</script>
